import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";
import Profile from "@/pages/Profile.vue";

import guest from './middleware/guest';
import auth from './middleware/auth';
import isHuskow from './middleware/isHuskow';
import isBrawks from './middleware/isBrawks';
import isAdmin from './middleware/isAdmin';

// Admin pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dashboard.vue");
const LaGrosseGame = () => import(/* webpackChunkName: "lagrossegame" */"@/pages/LaGrosseGame.vue");
const Huskow = () => import(/* webpackChunkName: "huskow" */"@/pages/Huskow.vue");
const Login = () => import(/* webpackChunkName: "huskow" */"@/pages/Login.vue");

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      middleware: [
        guest
      ]
    }
  },
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: "lagrossegame",
        name: "lagrossegame",
        component: LaGrosseGame,
        meta: {
          middleware: [
            auth,
            isBrawks
          ]
        }
      },
      {
        path: "huskow",
        name: "huskow",
        component: Huskow,
        meta: {
          middleware: [
            auth,
            isHuskow
          ]
        }
      },
      {
        path: "profile",
        name: "profile",
        component: Profile,
        meta: {
          middleware: [
            auth
          ]
        }
      }
    ]
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
