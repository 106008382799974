<template>
  <card>
    <h5 slot="header" class="title">Change password</h5>
    <form>
      <div class="row">
        <div class="col-md-6 pr-md-1">
          <base-input
            label="Password"
            placeholder="Type your new password"
            type="password"
            v-model="password.password"
            required
          >
          </base-input>
        </div>
        <div class="col-md-6 pl-md-1">
          <base-input
            label="Password Verification"
            type="password"
            placeholder="Retype your new password"
            v-model="password.verificationPassword"
            required
          >
          </base-input>
        </div>
      </div>
      <base-button
        slot="footer"
        type="primary"
        fill
        v-on:click="verifyPassword()"
        >Save</base-button
      >
    </form>
  </card>
</template>
<script>
export default {
  data() {
    return {
      password: {
        password: "",
        verificationPassword: ""
      }
    };
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    async modifyPassword() {
      const userPassword = {
        password: this.password.password
      };
      let userID = window.$cookies.get("user").id;
      fetch("https://cdn.teyz.fr:4000/users/" + userID, {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + window.$cookies.get("user").token,
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: userPassword
      })
        .then(res => res.json())
        .then(json => {
          console.log(json);
        })
        .catch(error => {
          console.log(error);
        });
    },

    verifyPassword() {
      if (
        this.password.password === "" &&
        this.password.verificationPassword === ""
      ) {
        this.notifyVueErrorPasswordEmpty();
        this.resetInput();
      } else if (
        this.password.password === "" ||
        this.password.verificationPassword === ""
      ) {
        this.notifyVueErrorOnePasswordEmpty();
        this.resetInput();
      } else if (this.password.password != this.password.verificationPassword) {
        this.notifyVueErrorPasswordNotEqual();
        this.resetInput();
      } else {
        this.modifyPassword();
        this.resetInput();
      }
    },

    notifyVueErrorPasswordEmpty() {
      this.$notify({
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 0,
        message: `Please enter a new password before submit`
      });
    },

    notifyVueErrorOnePasswordEmpty() {
      this.$notify({
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 0,
        message: `You have to fill both passwords fields`
      });
    },

    notifyVueErrorPasswordNotEqual() {
      this.$notify({
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 0,
        message: "Passwords are different, please retry"
      });
    },

    resetInput() {
      this.password.password = "";
      this.password.verificationPassword = "";
    }
  }
};
</script>
<style></style>
