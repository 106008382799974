export default function isBrawks({ next, store }) {
  if (window.$cookies.get("user") === null) {
    return next({
      name: 'dashboard'
    })
  } else if (!window.$cookies.get("user").isBrawks) {
    return next({
      name: 'dashboard'
    })
  } else {
    return next()
  }
}