<template>
  <div class="row">
    <div class="col-md-8">
      <edit-profile-form :model="profile"> </edit-profile-form>
      <EditPasswordForm :model="profile" />
    </div>
    <div class="col-md-4">
      <user-card :user="user"></user-card>
    </div>
  </div>
</template>
<script>
import EditProfileForm from "./Profile/EditProfileForm";
import EditPasswordForm from "./Profile/EditPasswordForm";
import UserCard from "./Profile/UserCard";
export default {
  components: {
    EditProfileForm,
    UserCard,
    EditPasswordForm
  },
  beforeMount() {
    this.getUser();
  },
  data() {
    return {
      profile: {
        username: "",
        name: "",
        lastname: "",
        creationDate: ""
      },
      user: {
        fullName: "",
        title: "Ceo/Co-Founder",
        description: `Do not be scared of the truth because we need to restart the human foundation in truth And I love you like Kanye loves Kanye I love Rick Owens’ bed design but the back is...`
      }
    };
  },
  methods: {
    async getUser() {
      let userID = window.$cookies.get("user").id;
      fetch("https://cdn.teyz.fr:4000/users/" + userID, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + window.$cookies.get("user").token,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(res => res.json())
        .then(json => {
          this.profile = json;
          let createdDate = new Date(this.profile.createdDate);
          createdDate = createdDate.toString();
          this.profile.creationDate = createdDate;
          this.user.fullName =
            this.profile.firstName + " " + this.profile.lastName;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
<style></style>
